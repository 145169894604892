import React, { useEffect, useState } from "react";
import zoom from "../../../assets/images/zoom.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ToggleSwitch from "../forms/ToggleSwitch";
import ToggleSwitchComponent from "./ToggleSwitchComponent";
import FloatingButton from "./FloatingButton";

const ZoomDetailsModal = (props) => {
  const [zoomConfig, setZoomConfig] = useState();
  const [zoomDefaultConfig, setZoomDefaultConfig] = useState();

  const handleUpdateZoomConfig = (field, value) => {
    setZoomConfig((prev) => {
      return {
        ...prev,
        [field]: value,
      };
    });
  };

  const handleGetZoomMeeting = (access_token) => {
    $.ajax({
      url: `../zoom_get_meeting?meetingId=${props.meetingId}`,
      dataType: "JSON",
      type: "POST",
      data: {
        access_token: props.zoomInfo.access_token,
      },
      header: {
        "Content-Type": "application/json",
      },
      success: (data) => {
        setZoomConfig({
          id: data.response?.id,
          password: data.response?.password,
          settings: {
            join_before_host: data.response?.settings?.join_before_host,
            mute_upon_entry: data.response?.settings?.mute_upon_entry,
            waiting_room: data.response?.settings?.waiting_room,
            auto_recording: data.response?.settings?.auto_recording,
          },
        });
        setZoomDefaultConfig({
          id: data.response?.id,
          password: data.response?.password,
          settings: {
            join_before_host: data.response?.settings?.join_before_host,
            mute_upon_entry: data.response?.settings?.mute_upon_entry,
            waiting_room: data.response?.settings?.waiting_room,
            auto_recording: data.response?.settings?.auto_recording,
          },
        });
      },
      error: (xhr, status, err) => {
        console.log(err);
      },
    });
  };

  useEffect(() => {
    handleGetZoomMeeting(props.zoomInfo);
  }, []);

  const handleUpateEventOnZoom = async () => {
    $.ajax({
      url: `../zoom_update_meeting?meetingId=${props.meetingId}`,
      dataType: "JSON",
      type: "POST",
      data: {
        access_token: props.zoomInfo.access_token,
        body: zoomConfig,
      },
      header: {
        "Content-Type": "application/json",
      },
      success: (data) => {
        props.onClose();
      },
      error: (xhr, status, err) => {
        console.log(err);
      },
    });
  };

  return (
    <div style={{ margin: 0 }}>
      <div className="zoom--details--modal">
        <div className="content">
          <div className="header">
            <img src={zoom} />
            <div className="xButton" onClick={() => props.onClose()}>
              <FontAwesomeIcon icon={faTimes} style={{ color: "#fff" }} />
            </div>
          </div>
          {!zoomConfig ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "70%",
                justifyContent: "center",
              }}
            >
              <div className="loading--ring">
                <div className="lds-dual-ring"></div>
              </div>
            </div>
          ) : (
            <>
              <div className="form--zoom">
                <div>
                  <h2>Meeting ID</h2>
                  <div className="content--row">
                    <span style={{ margin: "0" }}>
                      Generate Automatically <b>{zoomConfig?.id}</b>
                    </span>
                  </div>
                </div>
                <h2>Meeting Options</h2>
                <div className="content--row">
                  <div className="toggle">
                    <ToggleSwitchComponent
                      checked={zoomConfig?.settings?.join_before_host}
                      toggleSwitch={() => {
                        handleUpdateZoomConfig("settings", {
                          ...zoomConfig.settings,
                          join_before_host:
                            zoomConfig?.settings?.join_before_host === true
                              ? false
                              : true,
                        });
                      }}
                    />
                  </div>
                  <span>Enable join before Host</span>
                </div>
                <div className="content--row">
                  <div className="toggle">
                    <ToggleSwitchComponent
                      checked={zoomConfig?.settings?.mute_upon_entry}
                      toggleSwitch={() => {
                        handleUpdateZoomConfig("settings", {
                          ...zoomConfig.settings,
                          mute_upon_entry:
                            zoomConfig?.settings?.mute_upon_entry === true
                              ? false
                              : true,
                        });
                      }}
                    />
                  </div>
                  <span>Mute participants upon entry</span>
                </div>
                <h2>Security</h2>
                <div className="content--row">
                  <div className="toggle">
                    <ToggleSwitchComponent
                      checked={!!zoomConfig?.password}
                      readOnly={true}
                      toggleSwitch={() => {}}
                    />
                  </div>
                  <span>Require meeting password</span>
                  <input
                    value={zoomConfig?.password}
                    onChange={(e) => {
                      handleUpdateZoomConfig("password", e.target.value);
                    }}
                    maxLength={10}
                  />
                </div>
                <small style={{ margin: "0" }}>
                  Only users with the invitation link or password can join the
                  meeting
                </small>
                <div className="content--row">
                  <div className="toggle">
                    <ToggleSwitchComponent
                      checked={zoomConfig?.settings?.waiting_room}
                      toggleSwitch={() => {
                        handleUpdateZoomConfig("settings", {
                          ...zoomConfig.settings,
                          waiting_room:
                            zoomConfig?.settings?.waiting_room === true
                              ? false
                              : true,
                        });
                      }}
                    />
                  </div>
                  <span>Enable waiting room</span>
                </div>
                <h2>Video</h2>
                <div className="content--row--video">
                  <div className="video--row">
                    <div
                      className="checkbox--outside"
                      onClick={() => {
                        handleUpdateZoomConfig("settings", {
                          ...zoomConfig.settings,
                          auto_recording: "none",
                        });
                      }}
                    >
                      <div
                        className={
                          zoomConfig?.settings?.auto_recording === "none"
                            ? "checked"
                            : ""
                        }
                      />
                    </div>
                    <span>Don't Record</span>
                  </div>
                  <div className="video--row">
                    <div
                      className="checkbox--outside"
                      onClick={() => {
                        handleUpdateZoomConfig("settings", {
                          ...zoomConfig.settings,
                          auto_recording: "cloud",
                        });
                      }}
                    >
                      <div
                        className={
                          zoomConfig?.settings?.auto_recording === "cloud"
                            ? "checked"
                            : ""
                        }
                      />
                    </div>
                    <span>Zoom Cloud</span>
                  </div>
                  <div className="video--row">
                    <div
                      className="checkbox--outside"
                      onClick={() => {
                        handleUpdateZoomConfig("settings", {
                          ...zoomConfig.settings,
                          auto_recording: "local",
                        });
                      }}
                    >
                      <div
                        className={
                          zoomConfig?.settings?.auto_recording === "local"
                            ? "checked"
                            : ""
                        }
                      />
                    </div>
                    <span>Local Computer</span>
                  </div>
                </div>
              </div>
              <div className="buttons--footer">
                <button
                  className="default--button"
                  onClick={() => setZoomConfig(zoomDefaultConfig)}
                >
                  <h2>Reset to default</h2>
                </button>
                <FloatingButton
                  title="Save"
                  onClick={() => {
                    handleUpateEventOnZoom();
                  }}
                  disabled={
                    JSON.stringify(zoomConfig) ===
                    JSON.stringify(zoomDefaultConfig)
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
      <div
        onClick={() => props.onClose()}
        className="zoom--details--outside"
      ></div>
    </div>
  );
};

export default ZoomDetailsModal;
