import React, {useEffect, useState} from "react";

import BuyerInfo from "./BuyerInfo";
import RegistrationForm from "./RegistrationForm";

import CheckoutForm from "../components/CheckoutForm";       

import {UserStatus} from "../../utils/user";
import {ProductType} from "../../utils/product";
import {amountCentsToStringUSD} from "../../utils/currency";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";  

const PaymentStatus = {
  Initial: 0,
  Confirmed: 1,
  Failed: 2,
  Complete: 3,
  Registering: 4,
  Registered: 5,
}

const stripePromise = loadStripe(    
  process.env.STRIPE_PUBLISHABLE_KEY || 'pk_test_hwIAq3FDn5cj8EhgPpyT0ngM'
);                                   

const _THANK_YOU_TEXT = (
  "Thank you for <what>! We've emailed a confirmation link to\u00A0<email>."
);
const _DOWNLOAD_THANK_YOU_TEXT = _THANK_YOU_TEXT.replace("<what>", "your purchase");
const _EVENT_THANK_YOU_TEXT = _THANK_YOU_TEXT.replace("<what>", "registering");

const SUPPORT_EMAIL = process.env.SENDGRID_FROM_EMAIL;

const PaymentModal = ({
  // product metadata used to create a Charge and populate details
  productSpec,
  // buyer's info, populated if logged in
  currentUser,
  // called when the modal is closed (regardless of sale completion)
  onClose,
  // called when the modal is closed after sale completion - before 'onClose'
  onSuccess,
  // rendered at the last page of the modal; that is,
  // after an authenticated user's purchase confirmation,
  // or after a new user's account registration confirmation.
  renderModalFinale,
}) => {
  const [paymentStatus, setPaymentStatus] = useState(PaymentStatus.Initial);
  const [charge, setCharge] = useState();
  const [buyerInfo, setBuyerInfo] = useState();
  const [productMetadata, setProductMetadata] = useState();
  const [chargePollInterval, setChargePollInterval] = useState();
  const [errorResponse, setErrorResponse] = useState({
    prefix: undefined, err: undefined
  });

  window.scrollTo(0, 0);
  useEffect(() => {
    const scrollListenner = () => {
      if (window.scrollY > 1) {
        window.scrollTo(0, 0);
      }
    };

    window.addEventListener("scroll", scrollListenner);

    return () => {
      window.removeEventListener("scroll", scrollListenner);
    };
  }, []);

  const renderDivider = () => {
    return <hr />
  }

  useEffect(() => {
    if (productMetadata) {
      return;
    }

    productSpec.fetchMetadata().then((response) => {
      setProductMetadata(productSpec.deserializeMetadata(response))
    });
  }, [productSpec]);

  const renderHeader = () => {
    if (!productMetadata) {
      return null;
    }

    return (
      <>
        <div className="payment--thumbnail">                           
          <img src={productMetadata?.thumbUrl} alt="thumbnail" /> 
        </div>                                                         
        <h2>                                                           
          {productSpec.actionName}{" "}                         
          <b>{productMetadata.title}</b>                 
        </h2>                                                          
        {productMetadata.price ? (
          <p>{`You'll be charged ${amountCentsToStringUSD(productMetadata.price)}`}</p>        
        ) : null}
      </>
    );
  };

  const hasError = () => {
    return errorResponse.prefix || errorResponse.err;
  };

  const renderErrorResponse = () => {
    if (!hasError()) {
      return null;
    }
    let formatted = errorResponse.prefix;
    if (errorResponse.err) {
      if (errorResponse.err.responseJSON?.message) {
        formatted += ": " + errorResponse.err.responseJSON.message;
      } else if (errorResponse.err.responseText) {
        formatted += ": " + errorResponse.err.responseText;
      }
    }
    return (
      <div className="errorMessageContainer cleanScroll">
        <span className="errorMessage">{formatted}</span>
      </div>
    );
  };

  const renderModal = ({upperContent, lowerContent, showHeader}) => {
    return (
      <>
      <div className="modalOuterContainer">
        <div className="modalContainer paymentModal">
          <div className="modalContent">
            {showHeader && renderHeader()}
            {upperContent}
            {upperContent && lowerContent && renderDivider()}
            {renderErrorResponse()}
            {lowerContent}
          </div>
        </div>
        <div
          onClick={() => {
            if (paymentStatus == PaymentStatus.Complete && !hasError()) {
              onSuccess(charge.charge_code);
            }
            onClose();
          }}
          className="modalOutside"
        ></div>
      </div>
      </>
    );
  };

  let upperContent = null;
  let lowerContent = null;

  const createCharge = () => {
    return $.ajax({
      url: "/create_charge",
      dataType: "JSON",
      type: "POST",
      data: {productSpec: productSpec},
      header: {
        "Content-Type": "application/json",
      },
    });
  };

  const handleNewBuyerInfo = (newBuyerInfo, resetState) => {
    if (resetState) {
      setPaymentStatus(PaymentStatus.Initial);
      setBuyerInfo(newBuyerInfo);
      console.log("reset payment status on buyer info change");
      return;
    }

    if (charge) {
      updateCharge(newBuyerInfo, charge.charge_code);
    } else {
      createCharge().then((resp) => {
        setCharge(resp);
        updateCharge(newBuyerInfo, resp.charge_code);
      }, (err) => {
        console.error(err);
        setErrorResponse({prefix: "failed to create charge", err: err});
      });
    }
  }

  const updateChargeStatus = async () => {
    if (!charge) {
      return;
    }
    $.ajax({
      url: "/get_charge_status",
      dataType: "JSON",
      type: "POST",
      data: {chargeCode: charge.charge_code},
      header: {"Content-Type": "application/json"},
    }).then((resp) => {
      switch (resp["status"]) {
        default:
        case "FAILED":
          setPaymentStatus(PaymentStatus.Failed);
          break;
        case "SUCCEEDED":
          setPaymentStatus(PaymentStatus.Complete);
          break;
        case "OPEN":
          break;
      }
    }, (err) => {
      setErrorResponse({
        prefix: "failed to retrieve payment status",
        err: err,
      });
    });
  };

  useEffect(() => {
    let interval = undefined;
    if (paymentStatus === PaymentStatus.Confirmed && !chargePollInterval) {
      interval = setInterval(updateChargeStatus, 500);
      setChargePollInterval(interval);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
        setChargePollInterval(undefined);
      }
    }
  }, [paymentStatus]);

  const updateCharge = async (newBuyerInfo, chargeCode) => {
    $.ajax({
      url: "/update_charge",
      dataType: "JSON",
      type: "POST",
      data: {
        chargeCode: chargeCode,
        buyerInfo: newBuyerInfo,
      },
      header: {"Content-Type": "application/json"},
    }).then(() => {
      setBuyerInfo(newBuyerInfo);
      setPaymentStatus(PaymentStatus.Confirmed);
    }, (err) => {
      console.error(err);
      setErrorResponse(`failed to update charge: '${JSON.stringify(err)}'`);
    });
  };

  const renderLoadingAnimation = () => {
    return <p>Loading...</p>;
  };

  const renderCheckoutForm = () => {
    return (
      <div className="card--section">                 
        <Elements stripe={stripePromise}>             
          <CheckoutForm                               
            clientSecret={charge.charge_code}
            handleSuccess={() => {
              console.log("payment succeeded");
            }}
          />                                          
        </Elements>                                   
      </div>                                          
    );
  };

  const renderPaymentConfirmation = () => {
    const thankYouText = (
      productSpec.type === ProductType.Download ?
      _DOWNLOAD_THANK_YOU_TEXT :
      _EVENT_THANK_YOU_TEXT
    );

    return (
      <div className="payment--accept">
        <FontAwesomeIcon
          icon={faCheck}
          size="lg"
          style={{ color: "#C1A9FE", padding: "10px" }}
        />
        {thankYouText.replace("<email>", buyerInfo.email)}
      </div>
    );
  };

  const renderRegistrationConfirmation = () => {
    return <p>{
      `Thanks, ${buyerInfo.firstName}! ` + 
      `We've emailed a confirmation link to ${buyerInfo.email}. ` +
      `Click the link in the email to verify your account.`
    }</p>;
  };

  const renderFooter = () => {
    return (
      <p id="paymentModalFooter" style={{fontSize: "10px", color: "#888888"}}>
        By continuing, you agree to the{" "}
        <a href={`${process.env.WEB_URL}/terms`} target="_blank">
          terms
        </a>{" "}
        and{" "}
        <a href={`${process.env.WEB_URL}/privacy`} target="_blank">
          privacy policy
        </a>
        .
      </p>
    );
  };

  let showHeader = false;
  switch (paymentStatus) {
    default:
    case PaymentStatus.Initial:
      upperContent = (
        <BuyerInfo
          currentUser={currentUser}
          initialInfo={buyerInfo}
          onChange={(newBuyerInfo) => handleNewBuyerInfo(newBuyerInfo, false)}
          onError={setErrorResponse}
          showContinueButton={true}
        />
      );
      lowerContent = renderFooter();
      showHeader = true;
      break;
    case PaymentStatus.Confirmed:
      upperContent = (
        <BuyerInfo
          currentUser={currentUser}
          initialInfo={buyerInfo}
          onChange={(newBuyerInfo) => handleNewBuyerInfo(newBuyerInfo, true)}
          showContinueButton={false}
        />
      );
      if (!charge || charge.free_charge) {
        lowerContent = renderLoadingAnimation();
      } else {
        lowerContent = renderCheckoutForm();
      }
      showHeader = true;
      break;
    case PaymentStatus.Failed:
      upperContent = <p>Payment failed. Please contact support via  <a
        href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a></p>;
      break;
    case PaymentStatus.Complete:
      upperContent = renderPaymentConfirmation();
      if (buyerInfo.status === UserStatus.User) {
        lowerContent = renderModalFinale(charge.charge_code);
      } else {
        lowerContent = (
          <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "0em 5em",
          }}>
            <p><b>{
              "Let's stay in touch and make it easier for you to " +
              "discover future events and content!"
            }</b></p>
            <button
              className="button--payment button--payment--enabled"
              onClick={() => {
                setPaymentStatus(PaymentStatus.Registering);
              }}
            >
              <p>Save My Details</p>
            </button>
          </div>
        );
      }
      break;
    case PaymentStatus.Registering:
      upperContent = (
        <RegistrationForm
          buyerInfo={buyerInfo}
          onComplete={() => {setPaymentStatus(PaymentStatus.Registered)}}
          onError={setErrorResponse}
        />
      );
      break;
    case PaymentStatus.Registered:
      upperContent = renderRegistrationConfirmation();
      if (buyerInfo.status === UserStatus.New || buyerInfo.status === UserStatus.Guest) {
        lowerContent = renderModalFinale(charge.charge_code);
      }
      break;
  }

  return renderModal({
    upperContent: upperContent,
    lowerContent: lowerContent,
    showHeader: showHeader,
  });
};

export default PaymentModal;
