import React, { useEffect, useState } from "react";
import defaultPhoto from "../../../assets/images/default_photo.png";
import Modal from "../components/Modal";
import {TextInput} from "../components/Editor";
import TableView from "../components/TableView";

import {apiBase, asyncApiRequest} from "../../utils/apiRequest";
import {amountCentsToStringUSD} from "../../utils/currency";

import {format, parseISO} from "date-fns";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCopy, faDownload, faSearch, faTimes, faCheck, faPlus,
} from "@fortawesome/free-solid-svg-icons";
import Fuse from 'fuse.js'

function ContactTags({tags, subscribed, fontSize}) {
  if (!tags) {
    return null;
  }
  const getBgStyle = (text, subd) => {
    if (text === "Mailing list" && !subd) {
      return {
        border: "1px solid hsla(358, 75%, 48%, 1)",
        color: "#D81F26",
        background: "hsla(358, 100%, 94%, 1)",
      };
    }

    return {
      border: "1px solid #0a2175",
      background: "hsla(258, 100%, 97%, 1)",
    };
  };

  return JSON.parse(tags).map((x, i) => (
    <span
      key={i}
      style={{fontSize: (fontSize || "10px"), ...getBgStyle(x, subscribed)}}
      className="tw-p-1 tw-m-1 dark-border tw-rounded-lg"
    >{x}</span>
  ));
}

function SubscriptionStatus({subscribed, onChange, yesLabel, noLabel}) {
  if (subscribed) {
    return <div className="tw-flex tw-gap-2 tw-items-center">
      <div
        className={
          "tw-cursor-pointer dark-accent-background dark-accent-border tw-flex " +
          "tw-items-center tw-justify-center "
        }
        onClick={onChange}
        style={{height: "30px", width: "30px", borderRadius: "60px"}}>
        <FontAwesomeIcon icon={faCheck} className="tw-text-white" size="1x" />
      </div>
      <p className="text-14">{yesLabel || "Yes, subscribed to emails"}</p>
    </div>;
  }
  return <div className="tw-flex tw-gap-2 tw-items-center">
    <div
      className={
        "tw-cursor-pointer light-accent-background accent-border tw-flex " +
        "tw-items-center tw-justify-center "
      }
      onClick={onChange}
      style={{height: "30px", width: "30px", borderRadius: "60px"}}>
      <FontAwesomeIcon icon={faTimes} className="accent-text" size="1x" />
    </div>
    <p className="text-14">{noLabel || "No, not subscribed to emails"}</p>
  </div>
}


export default function ContactsContainer(props) {
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [contactsFuse, setContactsFuse] = useState();
  const [contactsList, setContactsList] = useState();
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [newContact, setNewContact] = useState({subscribed: true});
  const [query, setQuery] = useState();
  const [showContactModal, setShowContactModal] = useState(-1);

  const getContacts = async () => {
    const response = await apiBase("../get_user_contacts", "GET");

    const contacts = JSON.parse(response?.users); 
    setContactsList(contacts);
    const fuse = new Fuse(contacts, {
      keys: ["first_name", "last_name", "email"]
    });
    setContactsFuse(fuse);
  };

  const handleExport = () => {
    let csv = "Email,First name,Last name\n";

    filteredContacts.forEach(function (row) {
      csv += `${row.email},${row.first_name},${row.last_name}\n`;
    });

    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    hiddenElement.target = "_blank";

    // provide the name for the CSV file to be downloaded
    hiddenElement.download = "contacts.csv";
    hiddenElement.click();
  };

  useEffect(() => {
    getContacts();
  }, []);

  const flexClasses = "tw-flex tw-gap-4 tw-p-4";

  const renderEmptyState = () => {
    return <div className={`${flexClasses} tw-px-6 tw-flex-col tw-rounded-lg light-accent-border tw-w-fit`}>
      <p style={{fontSize: "40px", lineHeight: "normal"}}>{"📇"}</p>
      <p className="text-20 tw-font-semibold">Manage all your contacts in one place</p>
      <p className="text-12">Whenever someone interacts with on Tabu Wellness, their name and email are added to your contact list.</p>
      <button
        className="dark-accent-button"
        onClick={() => window.location = "/dashboard/create"}
      >Start creating</button>
    </div>;
  };

  const formatStamp = (stamp) => stamp ? format(parseISO(stamp), "MMM dd, yyyy") : "";

  useEffect(() => {
    setFilteredContacts(filterContacts(contactsList));
  }, [query, contactsList]);

  const filterContacts = (contacts) => {
    if (!query) {
      return contacts;
    }

    return contactsFuse.search(query).map(x => x.item);
  };

  const renderTable = () => {
    return <TableView
      content={filteredContacts}
      fields={[{
          title: "Name",
          value: c => `${c.first_name || ""} ${c.last_name || ""}`,
        }, {
          title: "Email",
          value: c => c.email,
        }, {
          title: "From",
          value: c => <ContactTags tags={c.tags} subscribed={true /* don't highlight red here */} />
        }, {
          title: "Since",
          value: c => formatStamp(c.created_at),
        }, {
          title: "Sub'd",
          value: c => c.subscribed ? (
            <FontAwesomeIcon icon={faCheck} className="dark-text" size="xs" />
          ) : (
            <FontAwesomeIcon icon={faTimes} className="dark-text" size="xs" />
          )
        }, {
          title: "Spent",
          value: c => amountCentsToStringUSD(c.total_spent),
        }
      ]}
      emptyState={renderEmptyState()}
      rowLinkFn={setShowContactModal}
    />
  };

  const renderSummary = () => {
    const totalC = contactsList.length;
    if (totalC === 0) {
      return null;
    }

    const totalEmail = contactsList.filter(c => c.subscribed).length;
    const totalCustomer = contactsList.filter(c => JSON.parse(c.tags)?.filter(x => (
      x !== "Mailing list")
    ).length > 0).length;

    const border = "3px solid hsla(258, 100%, 97%, 1)";
    const bigNumberStyle = {
      fontSize: "42px", lineHeight: "normal", color: "hsla(16, 80%, 72%, 1)"
    };

    return <div className="tw-p-8 tw-flex tw-gap-4 light-accent-border tw-rounded-lg">
      <div style={{borderRight: border}} className="tw-p-4 tw-flex tw-flex-col tw-gap-2 tw-pr-8">
        <p style={bigNumberStyle}>{totalC}</p>
        <p>Total contacts</p>
      </div>
      <div style={{borderRight: border}} className="tw-p-4 tw-flex tw-flex-col tw-gap-2 tw-pr-8">
        <p style={bigNumberStyle}>{totalEmail}</p>
        <p>Email subscribers</p>
      </div>
      <div className="tw-p-4 tw-flex tw-flex-col tw-gap-2">
        <p style={bigNumberStyle}>{totalCustomer}</p>
        <p>Customers & attendees</p>
      </div>
    </div>;
  };

  const renderContactDetails = (c) => {
    return <div className={`${flexClasses} tw-flex-col tw-w-full tw-box-border`}>
      <p className="tw-font-bold text-20">{`${c.first_name} ${c.last_name}`}</p>
      <p className="tw-font-light text-14">Contact since {formatStamp(c.created_at)}</p>
      <TextInput label="Email" disabled={true} defaultValue={c.email} />
      <p className="tw-font-semibold text-16">Subscribed</p>
      <SubscriptionStatus subscribed={c.subscribed} />
      <p className="tw-font-semibold text-16">Connections</p>
      <div className="tw-flex tw-flex-wrap tw-gap-2">
        <ContactTags fontSize="14px" tags={c.tags} subscribed={c.subscribed} />
      </div>
    </div>;
  }

  const renderAddContactModal = () => {
    const newContactValid = newContact.firstName && newContact.email;

    const addContact = async () => {
      asyncApiRequest("../create_user_contact", "POST", {
        user_id: props.user.id,
        first_name: newContact.firstName,
        last_name: newContact.lastName,
        email: newContact.email,
        tags: ["Imported"],
        subscribed: newContact.subscribed,
      }).then(() => {
        setShowAddContactModal(false);
        getContacts();
      });
    };

    const buttonStylin = (newContactValid) ?
      {} :
      {backgroundColor: "#ccc"};
    return <Modal
      onClose={() => setShowAddContactModal(false)}
      renderContent={() => (
        <div className={`${flexClasses} tw-flex-col tw-w-full`}>
          <TextInput
            label="First name"
            placeholder="First name"
            onChange={x => setNewContact({...newContact, firstName: x})}
          />
          <TextInput
            label="Last name (optional)"
            placeholder="Last name (optional)"
            onChange={x => setNewContact({...newContact, lastName: x})}
          />
          <TextInput
            label="Email"
            placeholder="Email"
            onChange={x => setNewContact({...newContact, email: x})}
          />
          <SubscriptionStatus
            yesLabel={"Yes, I have permission to send marketing emails to this person"}
            noLabel={"No, I don't wish to send marketing emails to this person"}
            subscribed={newContact.subscribed} onChange={() => (
            setNewContact({...newContact, subscribed: !newContact.subscribed})
          )} />
          <button
            className="dark-accent-button"
            style={buttonStylin}
            onClick={addContact}
            disabled={!newContactValid}
          >
            Add contact
          </button>
        </div>
      )}
    />
  }

  const renderContactModal = (id) => {
    return <Modal
      onClose={() => setShowContactModal(-1)}
      renderContent={() => renderContactDetails(filteredContacts[id])}
    />;
  };

  const renderSearchBar = () => {
    return <div className="tw-flex tw-gap-2 tw-items-center">
      <div
        className="tw-flex light-accent-border tw-grow" style={{borderRadius: "60px"}}
      >
        <button
          className={
            "tw-flex tw-items-center tw-justify-center dark-accent-background " +
            "tw-rounded-full"
          }
          style={{height: "25px", width: "25px", margin: "4px"}}
        >
          <FontAwesomeIcon icon={faSearch} className="tw-text-white" size="sm" />
        </button>
        <input
          style={{
            color: "#aaa", fontSize: "12px", margin: "4px", width: "0px",
            flexGrow: 1,
          }}
          placeholder="Search contacts"
          defaultValue={query}
          onChange={e => setQuery(e.target.value)}
        />
      </div>
      <button
        className="thick-dark-accent-border tw-rounded-full tw-flex tw-items-center tw-justify-center tw-cursor-pointer"
        style={{height: "30px", width: "30px", borderRadius: "60px"}}
        onClick={handleExport}
      >
        <FontAwesomeIcon icon={faDownload} className="dark-accent-text" size="1x" />
      </button>
    </div>;
  };

  return <div className={`${flexClasses} tw-w-full tw-flex-col tw-box-border`}>
    <div className={`${flexClasses} tw-justify-between`}>
      <p className="tw-text-4xl tw-font-bold dark-text">{`Here's who you're helping, ${props.user.first_name}!`}</p>
      <button
        className="dark-accent-button tw-flex tw-p-2 tw-gap-2"
        onClick={() => setShowAddContactModal(true)}
      >
        <FontAwesomeIcon icon={faPlus} className="tw-text-white" />
        <p className="tw-font-semibold tw-text-white">Add a contact</p>
      </button>
    </div>
    {!!contactsList && renderSummary()}
    {!!contactsList && renderSearchBar()}
    {!!contactsList && renderTable()}
    {showContactModal >= 0 && renderContactModal(showContactModal)}
    {!!showAddContactModal && renderAddContactModal()}
  </div>;
}
