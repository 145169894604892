import React from "react";
import PropTypes from "prop-types";

import Button from "../../../forms/Button";
import TextArea from "../../../forms/TextArea";
import NormalSelect from "../../../profile_forms/NormalSelect";
import TextInput from "../../../forms/TextInput";

class SendMessageProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      services: [],
      user: [],
      sendSuccess: false,
      userName: "",
      userEmail: "",
      userPhone: "",
      message: "",
    };

    this.renderServicesName = this.renderServicesName.bind(this);
    this.submitMessage = this.submitMessage.bind(this);
    this.updateField = this.updateField.bind(this);
    this.renderCurrentUserName = this.renderCurrentUserName.bind(this);
  }

  componentDidMount() {
    this.setState({
      userName: this.renderCurrentUserName(),
      userEmail: this.props.currentUser ? this.props.currentUser.email : "",
    });
  }

  renderCurrentUserName() {
    if (this.props.currentUser) {
      const { first_name, last_name } = this.props.currentUser;
      return last_name == null || last_name == ""
        ? `${first_name}`
        : `${first_name} ${last_name}`;
    } else {
      return "";
    }
  }

  updateField(attribute, text) {
    this.setState({
      [attribute]: text,
    });
  }

  submitMessage() {
    $.ajax({
      url: `../message_therapist`,
      dataType: "JSON",
      type: "POST",
      data: {
        message: this.state.message,
        user_id: this.props.user.id,
      },
      header: {
        "Content-Type": "application/json",
      },
      success: (data) => {
        this.setState({
          sendSuccess: true,
          message: "",
        });
      },
      error: function (xhr, status, err) {
        console.log(err);
      }.bind(this),
    });
  }

  renderServicesName() {
    const { user_services } = this.props.user;
    return user_services.map(({ id, name }) => ({ value: id, label: name }));
  }

  render() {
    return (
      <React.Fragment>
        {this.state.sendSuccess && (
          <div style={{ textAlign: "center" }}>
            <h3 style={{ marginBottom: 20 }}>Sent!</h3>
            <h5>Your provider will follow up with you via email.</h5>
          </div>
        )}
        {!this.state.sendSuccess && (
          <div className="message--profile">
            <div>
              <div style={{ marginBottom: 20 }}>
                <p
                  className="standard_label"
                  style={{ margin: "0 5px 10px 5px" }}
                >
                  Message
                </p>
                <p
                  className="standard_label"
                  style={{ margin: "0 5px 10px 5px", fontWeight: 400 }}
                >
                  {this.props.placeholder || (
                    "ex. Hi [name], my name is [your name] and I am looking for a " +
                    "therapist that specializes in [specialty] and [specialty]. Are " +
                    "you available for an intro phone call this week? Mornings work " +
                    "best for me. I look forward to hearing from you!"
                  )}
                </p>
                <TextArea
                  type="text"
                  className="profile_text_field"
                  attribute={"message"}
                  value={this.state.message}
                  handleChange={this.updateField}
                  style={{ width: "-webkit-fill-available", resize: "none" }}
                />
              </div>
              {this.props.user.user_services.length > 0 && (
                <div style={{ marginBottom: 20 }}>
                  <p
                    className="standard_label"
                    style={{ margin: "0 5px 10px 5px" }}
                  >
                    Interested in
                  </p>
                  <NormalSelect
                    options={this.renderServicesName()}
                    placeholder="Select therapist service"
                  />
                </div>
              )}
              <div style={{ marginBottom: 20 }}>
                <p
                  className="standard_label"
                  style={{ margin: "0 5px 10px 5px" }}
                >
                  Name
                </p>
                <TextInput
                  className="profile_text_field"
                  style={{ width: "-webkit-fill-available" }}
                  type="text"
                  attribute={"userName"}
                  value={this.state.userName}
                  handleChange={this.updateField}
                  placeholder="Name"
                />
              </div>
              <div className="modalMessageFieldContainer">
                <div>
                  <p
                    className="standard_label"
                    style={{ margin: "0 5px 10px 5px" }}
                  >
                    Email address
                  </p>
                  <TextInput
                    className="profile_text_field"
                    style={{ width: "-webkit-fill-available" }}
                    type="email"
                    attribute={"userEmail"}
                    value={this.state.userEmail}
                    handleChange={this.updateField}
                    placeholder="Email"
                  />
                </div>
              </div>
              <p
                className="standard_label"
                style={{
                  fontSize: 12,
                  margin: "0 5px 20px 5px",
                  fontWeight: 400,
                }}
              >
                This message will be sent by e-mail, which is not a secure means
                of communication. The Tabu Wellness team will receive a copy for our
                records to ensure a quality experience. Sending an email using
                this page does not guarantee that the recipient will receive,
                read or respond to your email. No licensed medical
                professional/patient relationship is created by submitting this
                form.
              </p>
              <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                <Button
                  style={{ width: 150 }}
                  buttonClass="button_primary"
                  text="Send Message"
                  handleClick={this.submitMessage}
                />
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default SendMessageProfile;
