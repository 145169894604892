import React, {useEffect, useState} from "react";

import BasicTierImage from "../../../../assets/images/default_photos/blobs_of_jupiter.png";
import MarketplaceTierImage from "../../../../assets/images/default_photos/sweet_floral_raindrops.png";

import MarketplacePrefsModal from "./MarketplacePrefsModal";
import ResponsiveModal from "../../components/ResponsiveModal";

import {asyncApiRequest} from "utils/apiRequest";

const csrf = document
  .querySelector("meta[name='csrf-token']")
  .getAttribute("content");

const cancelSubscription = () => {
  asyncApiRequest("/cancel_marketplace_subscription", "POST").then(() => location.reload());
}

function UpgradeCard(props) {
  const {
    background, isCurrent, title, image, content, button
  } = props;
  return <div
    style={{borderRadius: "20px", background: background}}
    className="tw-flex tw-flex-col tw-gap-4 tw-items-start tw-p-8 tw-box-border tw-w-[20rem] tw-max-w-[50%] tw-h-fit light-accent-border tw-self-end">
    {!!isCurrent && <p className="accent-border tw-p-2 tw-bg-white dark-text">Your current plan</p>}
    <p className="text-26 tw-font-bold tw-leading-snug">{title}</p>
    <div className="tw-overflow-hidden tw-w-[80px] tw-h-[80px] tw-border-white tw-border-2 tw-border-solid tw-rounded-full">
      <img src={image} alt="plan thumbnail" />
    </div>
    {content}
    {!!button && button}
  </div>;
}

export default function Upgrade({user}) {
  const params = new URLSearchParams(window.location.search);
  const [showModal, setShowModal] = useState(!!params.get("showModal"));
  const [showCancelModal, setShowCancelModal] = useState(false);
  const canceled = !!params.get("cancel");

  const buttonStyle = {width: "100%", margin: "0 1rem", alignSelf: "center"};
  const downgradeButton = <button style={{
      border: "1px solid #0A2175",
      boxShadow: "2px 4px 0px 0px #0A2175",
      borderRadius: "10px",
      padding: "0.5rem",
      fontWeight: 600,
      ...buttonStyle,
    }}
    onClick={() => setShowCancelModal(true)}
    >Downgrade</button>;
  const updatePrefsButton = <button style={buttonStyle} className="dark-accent-white-button"
    onClick={() => setShowModal(true)}
    >Update preferences</button>
  const levelUpButton = <form method="post" action="/create_marketplace_checkout_session"
    className="tw-w-full tw-flex">
    <input type="hidden" name="authenticity_token" value={csrf} />
    <button style={buttonStyle} className="dark-accent-button" type="submit"
    >Level up!</button>
  </form>;

  const baseFeatures = [
    "Profile",
    "Link-in-Bio Builder",
    "Digital Products E-comm",
    "Contact Management",
    "Mailing List ",
    "Income Dashboard",
    "7% transaction fees (+ Stripe*)",
  ];

  const upgradeFeatures = [
    `Everything in "Let's Do This"`,
    "Marketplace Promotion & Visibility",
    "Video Content E-comm (coming soon)",
    "0% transaction fees (+ Stripe*)",
  ];

  const subHeadingClasses = "text-12 tw-font-bold tw-pt-[5px]";

  const letUsDoThis = <div className="tw-flex tw-flex-col tw-gap-1">
    <p className="text-20 tw-font-semibold">Free</p>
    <p className="text-12">{
      "For social media-savvy providers with an existing audience or caseload."
    }</p>
    <p className={subHeadingClasses}>Includes</p>
    {baseFeatures.map((feature, i) => <p className="text-12" key={i}>{"✓  " + feature}</p>)}
  </div>;

  const levelUp = <div className="tw-flex tw-flex-col tw-gap-1">
    <p className="text-20 tw-leading-snug tw-font-semibold">
      <span className="tw-line-through">$29/mo</span>
      {" $9/mo through March!"}
    </p>
    <p className="text-12">{
      "For entrepreneurial providers ready to level up. " +
      "You want to attract more clients & customers to your services " +
      'and “beyond 1:1” offerings.'
    }</p>
    <p className={subHeadingClasses}>Join the directory!</p>
    <p className="text-12">Market your products & services in our consumer marketplace. We’ll match you with your ideal clients and promote your work.</p>
    <p className={subHeadingClasses}>Includes</p>
    {upgradeFeatures.map((feature, i) => <p className="text-12" key={i}>{"✓  " + feature}</p>)}
  </div>;

  return <div className="tw-items-center tw-flex tw-flex-col tw-gap-2 tw-w-fit">
    {showCancelModal && (
      <ResponsiveModal close={() => setShowCancelModal(false)}>
        <div className="tw-flex tw-flex-col tw-gap-2">
          <p>Are you sure you want to cancel your membership?</p>
            <button
              className="dark-accent-button"
              onClick={() => setShowCancelModal(false)}>No, go back</button>
            <button
              className="dark-accent-button"
              onClick={cancelSubscription}>Yes, cancel</button>
        </div>
      </ResponsiveModal>
    )}
    {showModal && <MarketplacePrefsModal user={user} close={() => setShowModal(false)} />}
    <div className="tw-flex tw-gap-2">
      <UpgradeCard
        background="linear-gradient(180deg, #F3EEFF 0%, rgba(255, 255, 255, 0) 123.11%)"
        isCurrent={!user.marketplace_subscription_active}
        title="Let's Do This"
        image={BasicTierImage}
        content={letUsDoThis}
        button={!!user.marketplace_subscription_active && downgradeButton}
      />
      <UpgradeCard
        background={
          "linear-gradient(360deg, rgba(255, 255, 255, 0) -3.3%, rgba(226, 215, 255, 0.465) 59.48%, #DACBFF 113.9%)"
        }
        isCurrent={user.marketplace_subscription_active}
        title="✨ Level Up ✨"
        image={MarketplaceTierImage}
        content={levelUp}
        button={user.marketplace_subscription_active ? updatePrefsButton : levelUpButton}
      />
    </div>
    <p className="text-12 dark-text">* to make payments totally seamless, we use <span className="dark-accent-text">Stripe</span>, which takes 2.9% + $0.30 per transaction</p>
  </div>;
}
